/**
 * Styles related to the admin and other Drupal system utilities
 * Many from "Kalatheme > tweaks.css"
 */

/* Node edit form */
#node-edit #edit-title {
  height: auto;
}

/* Views */
.views-ajax-popup .scroll fieldset {
  padding: 5px;
}

.views-ui-display-tab-bucket .views-display-setting .label {
  color: #666;
  font-size: 100%;
}

.views-ui-display-tab-actions .ctools-button-processed li a,
.views-ui-display-tab-actions .ctools-button-processed input {
  color: #000;
}

#admin-menu,
#admin-menu .dropdown {
  z-index: 9999;
}

.views-display-setting .label, .views-display-setting .views-ajax-link {
  float: none;
}

/* Tables */
table .active {
  background-color: transparent;
}

th.checkbox,
td.checkbox {
  display: table-cell;
}

.update tbody > tr:nth-child(odd) > td, .update tbody > tr:nth-child(odd) > th,
  .update tbody > tr:nth-child(even) > td, .update tbody > tr:nth-child(even) > th {
  background: transparent;
}

table.package tr.enabling, tr.selected td, tr.dblog-error, tr.dblog-notice, tr.dblog-warning,
  tr.dblog-error .active, tr.dblog-notice .active, tr.dblog-warning .active {
  background: transparent;
}

tr.even, tr.odd {
  background-color: transparent;
  border-bottom: inherit;
  padding: inherit;
}

table th.checkbox input, table td.checkbox input {
  float: none !important;
  margin-left: 0 !important;
}

/* Fieldsets */
fieldset legend {
  float: left;
  line-height: 1em;
  margin: 0;
}

fieldset .panel-body {
  clear: both;
}

.collapse-processed .fieldset-wrapper {
  margin-left: 25px;
  margin-bottom: 10px;
}

/* Vertical Tabs */
div.vertical-tabs {
  border: none;
  border: 1px solid #DDD;
  outline: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

div.vertical-tabs ul.vertical-tabs-list {
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  outline: none;
}

div.vertical-tabs ul.vertical-tabs-list li {
  border: none;
  background: none;
  outline: none !important;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  border: 1px solid;
  border-color: #fff #ddd #fff #fff;
}

div.vertical-tabs ul.vertical-tabs-list li.selected {
  border-color: #ddd #fff #ddd #ddd;
}

div.vertical-tabs ul.vertical-tabs-list li a {
  outline: none !important;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

div.vertical-tabs .vertical-tabs-panes {
  padding: 20px 10px;
}

div.vertical-tabs .form-type-textfield input {
  width: 90%;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* Misc */
ul.links {
  padding: 0;
  list-style: none outside none;
}

/* Default throbber needs some help for when FontAwsome isn't on. */
html.js input[autocomplete] {
  background-repeat: no-repeat;
}

/* Handle the FontAwesome throbber */
html.js input[autocomplete].fa-on,
html.js input.throbbing.fa-on {
  background: transparent;
}

.form-control-feedback {
  width: 13px;
  height: 13px;
  color: #bbb;
  top: 60%;
  right: 10px;
}

/* Same as adding FontAwesome fa-spin but faster */
input.throbbing + .form-control-feedback {
  -webkit-animation: throbber-spin 1s infinite linear;
          animation: throbber-spin 1s infinite linear;
}

/* Set a darker color when spinning */
input.throbbing + .form-control-feedback:before {
  color: rgba(95, 95, 95, 1);
}

/* Copied from FontAwesome fa-spin */
@-webkit-keyframes throbber-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes throbber-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

form .form-actions .btn {
  margin-right: 10px;
}

/* Make sure fieldsets collapse nicely */
html.js fieldset.collapsed {
  border-bottom-width: 0px;
  border-left-width: 1px;
  border-right-width: 1px;
  height: auto;
}

#main-wrapper {
  margin-bottom: 50px;
  margin-top: 20px;
}

.page-admin .pane-content .container {
  width: auto;
}

#module-filter-squeeze {
  background-color: transparent;
  border: 0;
}

.version-links .links {
  list-style-type: none;
}
/**
 * Box Sizing Resets
 * Provides a basic reset class as well.
 */
.draggable a.tabledrag-handle .handle,
.panopoly-spotlight-label,
.mceEditor,
#pane-library table,
.box-sizing-content-box {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

#edit-module-filter-name {
  display: inline;
}

label.option {
  display: inline;
  font-weight: normal;
}

.media-list-thumbnails .form-type-checkbox {
  bottom: 0;
}

div#admin-toolbar.vertical {
  z-index: 9999;
}

#tabs {
  margin-bottom: 20px;
}

/* Keep Bootstrap from hiding batch API progress message */
.batch-processed .progress {
  height: auto;
}

/* Make IPE region buttons inline */
ul.panels-ipe-linkbar {
  list-style: none outside none;
}

/* Cramped features display */
#features-export-form .features-export-parent {
  margin: 0 0 20px;
}

.features-export-component .checkbox label {
  margin-left: 10px;
}

.features-export-component .radio input[type="radio"],
.features-export-component .radio-inline input[type="radio"],
.features-export-component .checkbox input[type="checkbox"],
.features-export-component .checkbox-inline input[type="checkbox"],
.features-export-list .radio input[type="radio"],
.features-export-list .radio-inline input[type="radio"],
.features-export-list .checkbox input[type="checkbox"],
.features-export-list .checkbox-inline input[type="checkbox"] {
  margin-left: 0px;
}

/* IPE Modal */
.option-text-aligner {
  clear: none;
  
  .form-item {
    float: none;
  }
}