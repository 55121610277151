/**
 * Styles targeting the global footer
 *
 */

footer {
  padding: 20px 0 0;
  color: $wcm-med-gray;
  @include clearfix();
}

/* =Footer Contact Section
----------------------------------------------------------*/

.footer-contact {
  margin-bottom: 20px;
  background: $wcm-dark-orange;
  color: $wcm-white;
  @include clearfix();
  @include breakpoint($sm) {
    height: 60px;
    text-align: inherit;
  }
}

.footer-contact > .container {
  @include breakpoint($xs) {
    padding: 0;
  }
}

.footer-contact__map-link a {
  float: left;
  width: 60px;
  height: 60px;
  margin-right: 20px;
  color: $wcm-white;
  background-color: $wcm-bright-orange;
  font: 0/0 a;
  @include breakpoint($sm) {
    background-color: $wcm-white;
    color: $wcm-dark-orange;
  }
  &:before {
    content: '\e803';
    width: 60px;
    font-size: 36px;
    @include fontello();
    @include v-center();
  }
  &:hover {
    background-color: $wcm-bright-orange;
    color: $wcm-white;
    text-decoration: none;
  }
}

.footer-contact__site-name {
  display: none;
  @include breakpoint($md) {
    display: block;
    height: 60px;
    float: left;
  }
}

.footer-contact__site-name a {
  display: block;
  color: $wcm-white;
  font-family: $wcm-bold;
  font-size: 18px;
  @include v-center();
  &:hover {
    color: $wcm-white;
  }
  &:focus {
    color: $wcm-white;
  }
}

.footer-contact__contact-us a {
  position: relative;
  display: block;
  float: right;
  height: 60px;
  padding: 0;
  color: $wcm-white;
  text-align: center;
  font-family: $wcm-bold;
  @include breakpoint($xs) {
    width: 60px;
    @include text-hide();
    background-color: $wcm-bright-orange;
  }
  @include breakpoint($sm) {
    margin: 0 0 0 16px;
    padding: 20px 48px;
    background: $wcm-white;
    color: $wcm-dark-orange;
    font-size: 18px;
    line-height: 25px;
    &:hover {
      color: $wcm-white;
      background-color: $wcm-bright-orange;
    }
  }
  &:before {
    content:'\e80c';
    width: 60px;
    color: $wcm-white;
    font-size: 30px;
    @include fontello();
    @include v-center();
    @include breakpoint($sm) {
      display: none;
    }
  }
  &:hover {
    text-decoration: none;
  }
}

.footer-contact__social-links {
  margin: 0;
  @include breakpoint($md) {
    display: block;
    float: right;
    height: 60px;
  }
}

.footer-contact__social-links li {
  display: inline-block;
  height: 60px;
  padding: 0 4px;
  @include text-hide();
  @include breakpoint($sm) {
    padding: 0 10px;
  }
}

.footer-contact__social-links a {
  color: $wcm-white;
  &:before {
    font-size: 26px;
    @include fontello();
    @include v-center;
    @include breakpoint($sm) {
      font-size: 30px;
    }
  }
  &:hover {
    color: $wcm-yellow;
    text-decoration: none;
  }
}

.footer-contact__facebook a {
  &:before {
    content:'\f308';
  }
}

.footer-contact__twitter a {
  &:before {
    content:'\e815';
  }
}

.footer-contact__linkedin a {
  &:before {
    content:'\e806';
  }
}

.footer-contact__youtube a {
  &:before {
    content:'\f16a';
  }
}

.footer-contact__instagram a {
  &:before {
    content:'\f16d';
  }
}

.footer-contact__vimeo a {
  &:before {
    content:'\f27d';
  }
}

/* =Footer Address
----------------------------------------------------------*/

.footer-site-info {
  @include clearfix();
  @include breakpoint($sm) {
    border-bottom: 5px solid $wcm-dark-orange;
  }
}

.footer-address {
  display: none;
  @include clearfix();
  @include breakpoint($sm) {
    display: block;
    width: 18%;
    float: left;
    margin: 0 0 20px;
    padding-right: 30px;
    font-size: 13px;
    border-right: 1px solid $wcm-border-gray;
  }
}

.footer-address__address {
  float: left;
  margin-bottom: 20px;
  line-height: 24px;
}

/* =Footer Navigation
----------------------------------------------------------*/

.footer-nav {
  font-size: 12px;
  @include clearfix();
  @include breakpoint($xs) {
    margin: 25px 0;
    text-align: center;
  }
  @include breakpoint($sm) {
    width: 82%;
    float: left;
    padding-left: 40px;
  }
}

.footer-nav ul {
  margin: 0;
  padding: 0;
}

.footer-nav li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

.footer-nav a {
  color: $wcm-med-gray;
  &:hover {
    color: $wcm-bright-orange;
  }
}

.footer-nav .level-1 {
  padding: 0 0 5px;
}

.footer-nav .level-1 ul {
  display: none;
  @include breakpoint($md) {
    display: block;
  }
}

.footer-nav .level-1 ul:first-of-type {
  padding: 5px 0 0;
}

.footer-nav .level-1 > a {
  font-weight: 700;
}

.footer-nav .level-3 {
  padding: 0 0 5px 10px;
}

.footer-nav .level-1 .expand-menu {
  display: none;
}

/* =Footer Statement
----------------------------------------------------------*/

.footer-statement {
  display: none;
  @include breakpoint($sm) {
    clear: both;
    display: block;
    border-bottom: 5px solid $wcm-dark-orange;
    margin: 0;
    padding: 22px 0 15px 0;
    @include clearfix();
  }
}

.footer-statement__emblem {
  width: 62px;
  height: 62px;
  background-image: url(../images/WCMC-EMBLEM_web_red.png);
  background-size: 62px;
  background-position: left;
  padding-left: 0;
  margin: 0 auto 1em;
  @include breakpoint($sm) {
    float: left;
    margin-right: 20px;
  }
}

.footer-statement__statement {
  font-size: 15px;
  line-height: 25px;
  font-family: $wcm-italic;
  @include breakpoint($sm) {
    width: 87%;
    float: left;
    padding-top: 6px;
  }
  strong {
    font-family: $wcm-bold;
    font-style: italic;
  }
}

.footer-statement__person {
  color: $wcm-dark-orange;
  margin-top: 8px;
  font-size: 14px;
  font-family: $wcm-regular;
}

/* =Footer Partnership
----------------------------------------------------------*/

.footer-partnership {
  margin: 10px 0 20px;
  text-align: center;
  @include clearfix();
  @include breakpoint($xs) {
    border-top: 5px solid #cf4520;
  }
}

.footer-partnership__wcm {
  display: inline-block;
  width: 250px;
  height: 24px;
  background: url(../images/wcm_logo.svg) no-repeat center left;
  background-size: 250px 24px;
  @include text-hide();
}

.footer-partnership__nyp {
  display: inline-block;
  width: 209px;
  height: 24px;
  background: url(../images/nyp_logo.svg) no-repeat center left;
  background-size: 209px 24px;
  @include text-hide();
  @include breakpoint((min: 570px)) {
    margin-left: 20px;
  }
  @include breakpoint($sm) {
    margin-left: 80px;
  }
}

/* =Footer Links
----------------------------------------------------------*/

.footer-links {
  border-top: 1px solid $wcm-border-gray;
  padding: 30px 0;
  text-align: center;
}

.footer-links__copyright {
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
  @include breakpoint($sm) {
    margin: 0;
  }
}

.footer-links__menu {
  @include breakpoint($sm) {
    display: inline-block;
  }
}

.footer-links__menu ul {
  margin: 0 0 0 10px;
  padding: 0;
  font-size: 12px;
}

.footer-links__menu li {
  list-style-type: none;
  margin-bottom: 10px;
  @include breakpoint($sm) {
    float: left;
    margin: 0 24px 0 2px;
    list-style-type: disc;
    list-style-position: outside;
  }
  &:first-child {
    @include breakpoint($sm) {
      list-style-type: none;
      list-style-image: none;
    }
  }
}

.footer-links__menu a {
  color: $wcm-med-gray;
  @include transition(color .25s);
  &:hover {
    color: $wcm-bright-orange;
    text-decoration: none;
  }
}
