/**
 * Styles targeting various components such as the breadcrumbs or sidebar items
 *
 */

/* =Structure
----------------------------------------------------------*/

.site-main {
  margin: 25px 0 0;

  @include breakpoint($md) {
    margin: 0;
  }
}

/* =Branding
----------------------------------------------------------*/

.brand__nav {
  @include breakpoint($xs) {
    margin: 0 0 5px;
  }
}

.brand__lockup a {
  border: none;
}

/* =General Responsive Image Style
----------------------------------------------------------*/

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

/* =Alerts
----------------------------------------------------------*/

.alert {
  margin: 20px 0;
}

/* =Breadcrumb
----------------------------------------------------------*/

.breadcrumb {
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  padding: 20px 0 20px;
  background: none;
  color: $wcm-med-gray;
}

.breadcrumb a {
  color: #666666;
  text-decoration: none;
  border-bottom: 1px dotted;

  &:hover {
    color: $wcm-bright-orange;
    border-bottom: 1px solid;
    text-decoration: none;
  }
}

.breadcrumb span {
  padding: 0 5px;
}

/* =Hero Image / Caption
----------------------------------------------------------*/

.hero-image {
  @include clearfix();
}

figcaption,
.hero-image-full-caption {
  color: $wcm-med-gray;
  font-size: 13px;
  font-style: italic;

  @include breakpoint($sm) {
    float: right;
    width: 500px;
    text-align: right;
  }
}

.panel-fullhero-3col .hero-section.col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

.hero-image-full-caption {
  width: auto;
  float: none;
}

.hero-image-full-caption>.container {
  @include breakpoint($sm) {
    width: 760px;
  }

  @include breakpoint($md) {
    width: 980px;
  }

  @include breakpoint($lg) {
    width: 1180px;
  }
}


/* =Panopoly Images
----------------------------------------------------------*/

.caption.panopoly-image-quarter {
  @include breakpoint($xs) {
    max-width: 100% !important;
  }
}

/* =Inline Images
----------------------------------------------------------*/

div.caption-left {
  float: none;
  margin: 20px 0;

  @include breakpoint($md) {
    float: left;
    margin: 20px 40px 20px 0;
  }
}

div.caption-right {
  float: none;
  margin: 20px 0;

  @include breakpoint($md) {
    float: right;
    margin: 20px 0 20px 40px;
  }
}

div.caption-inner {
  background: transparent;
  border: none;
}

div.caption img,
div.caption object {
  margin-bottom: 20px;
}

.caption-text {
  text-align: left;
}

/* =Contact Info Sidebar Pane
----------------------------------------------------------*/

.contact-info {
  text-align: center;
}

.contact-info span {
  display: block;
}

.contact-info .site-name {
  font-weight: 700;
}

/* =Related Content
----------------------------------------------------------*/

#related-content-sidebar {
  font-size: 14px;
  line-height: 24px;
}

#related-content-sidebar .panel-pane,
#related-content-inline .panel-pane {
  margin-bottom: 20px;
}

#related-content-sidebar .pane-title {
  margin: 10px 0 5px;
  color: $wcm-med-gray;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#related-content-sidebar ul {
  padding-left: 20px;
}

/* =Jump to Top
----------------------------------------------------------*/

.jump-to-top {
  display: none;
  width: 200px;
  margin: 30px auto;
}

.jump-to-top>a {
  border: none;
  width: 200px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  line-height: .7;
  color: $wcm-med-gray;
  opacity: .75;
  @include transition(opacity 0.25s);

  &:before {
    font-size: 18px;
    line-height: inherit;
    content: '\e80b';
    text-decoration: none;
    color: $wcm-bright-orange;
    @include fontello();
    @include transition(all 0.25s);
  }

  &:hover:before {
    font-size: 28px;
  }
}

.jump-to-top>a span {
  display: block;
  border-top: 1px solid $wcm-border-gray;
  padding-top: 10px;
  margin-top: 10px;
}

/* =Second Level Nav / Third Level Nav Panes
----------------------------------------------------------*/

#second-level-nav,
#third-level-nav,
#wcm-sub-nav {
  @include button-nav;
}

#active-third-level-nav .level-3-select a {
  color: $wcm-dark-orange;
}

/* =Video Panes/Embeds
----------------------------------------------------------*/

.pane-bundle-video a>img {
  display: block;
  margin: 0 auto;
}

.video {
  position: relative;
  margin: 0;
}

.fancybox-video {
  position: relative;
  display: block;
  border: 1px solid $wcm-bright-orange;
}

.video .playhead {
  width: 48px;
  height: 34px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -17px;
  margin-left: -24px;
  color: white;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
  z-index: 100;
  @include transition(background-color .25s);
  @include text-hide();
  background-color: $wcm-dark-gray;

  @include breakpoint($sm) {
    margin-top: -10%;
  }

  &:hover+a>.related-media-overlay {
    opacity: 0;
  }

  &:before {
    display: block;
    content: '\e80d';
    color: $wcm-border-gray;
    font-size: 18px;
    @include fontello();
  }
}

// Responsive YouTube videos
.media-youtube-video {
  @include responsive-embed();
}

// Responsive Map iFrames
.field-map-address {
  .field-content-items {
    @include responsive-embed();
  }
}

.field-map-information {
  margin-top: 10px;
}

// Remove border from iFrames
iframe {
  border: none;
}

/* =Slideshow Panes
----------------------------------------------------------*/

.pane-bundle-slideshow {
  margin-bottom: 30px;
  font-size: 14px;

  @include breakpoint($sm) {
    font-size: 12px;
  }
}

.pane-bundle-slideshow p {
  font-size: 12px;
  display: inline;
}

.slideshow-images {
  position: relative;
  border: 1px solid $wcm-bright-orange;
}

.photo-icon {
  width: 48px;
  height: 44px;
  z-index: 100;
  @include text-hide();
  @include center;

  &:before {
    content: '\e80e';
    color: $wcm-dark-gray;
    font-size: 44px;
    @include fontello();
    display: block;
  }

  &:hover {
    text-decoration: none;
  }

  &:hover+.related-media-overlay {
    opacity: 0;
  }

  &:hover:before {
    text-decoration: none;
  }
}

.related-media-overlay {
  background: $wcm-white;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0.4;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  z-index: 10;
  cursor: pointer;
  @include transition(opacity .25s);

  &:hover {
    opacity: 0;
  }
}

/* =Sharing Icons
----------------------------------------------------------*/

.social-share {
  margin: 15px 0;
  padding: 0;
}

.social-share li {
  display: inline-block;
  height: 30px;
  padding: 0 5px;
  @include text-hide();
}

.social-share a {
  color: $wcm-dark-orange;
  border: none;

  &:before {
    font-size: 22px;
    @include fontello();
    @include v-center;
  }

  &:hover {
    color: $wcm-yellow;
    text-decoration: none;
  }
}

.social-share__facebook a {
  &:before {
    content: '\e804';
  }
}

.social-share__twitter a {
  &:before {
    content: '\e815';
  }
}

.social-share__linkedin a {
  &:before {
    content: '\e806';
  }
}

.social-share__email a {
  &:before {
    content: '\e80c';
  }
}

/* =CTA Icons
----------------------------------------------------------*/

.wcm-cta {
  width: 220px;
  margin: 0 auto 20px;
  @include clearfix();
}

.wcm-cta__tile {
  position: relative;
  display: block;
  width: 110px;
  height: 110px;
  float: left;
  border: 1px solid $wcm-border-gray;
  z-index: 10;

  &:nth-child(1) {
    border-width: 0 1px 0 0;
  }

  &:nth-child(2) {
    border-width: 0 0 0 0;
  }

  &:nth-child(3) {
    border-width: 1px 1px 0 0;
  }

  &:nth-child(4) {
    border-width: 1px 0 0 0;
  }

  // Put the box shadow on a psuedo-element
  // http://tobiasahlin.com/blog/how-to-animate-box-shadow/
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
  }

  &:hover {
    z-index: 1000;
    text-decoration: none;
    -webkit-transform: scale(1.1, 1.1);
    /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: scale(1.1, 1.1);
    /* IE 9 */
    transform: scale(1.1, 1.1);
    /* IE 10, Fx 16+, Op 12.1+ */
    border-width: 1px;
    border-color: $wcm-border-gray;
    background: $wcm-white;
  }

  &:hover:after {
    opacity: 1;
  }
}

.wcm-cta__tile.external-link>.wcm-cta__detail span {
  &:after {
    content: '\e811';
    padding-left: 5px;
    @include fontello();
  }
}

.wcm-cta__detail {
  width: 85%;
  font-size: 12px;
  line-height: 16px;
  @include center();
}

.wcm-cta__tile .cta-icon {
  width: 40px;
  height: 40px;
  fill: $wcm-bright-orange;
  color: $wcm-dark-orange;
}

.wcm-cta__tile {

  .cta-icon-facebook,
  .cta-icon-instagram,
  .cta-icon-linkedin,
  .cta-icon-youtube,
  .cta-icon-twitter,
  .cta-icon-vimeo,
  .cta-icon-flickr,
  .cta-icon-google-plus {
    fill: #fff;
  }
}

.wcm-cta__tile span {
  display: block;
  text-align: center;
}

// Don't need to show external link icon on the tile
.wcm-cta__tile.external-link:after {
  content: '';
  padding-left: 0;
}

/* =Pagination
----------------------------------------------------------*/

.pagination {

  >li>a,
  >li>span {

    &:hover,
    &:focus {
      color: $wcm-red;
      border: 1px solid $wcm-border-gray;
    }
  }
}

/* =Element Hide and Show & CTools Collapsable Container
----------------------------------------------------------*/

.element__show-hide {
  position: relative;
  @include clearfix();
}

.element__toggle,
.ctools-collapsible-container .pane-title,
.ctools-collapsible-container h2 {
  position: relative;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
  color: $wcm-dark-orange;
  border: 1px solid $wcm-border-gray;
  border-width: 1px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600;

  &:after,
  &.ctools-collapsed:after {
    content: '+';
    width: 50px;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-left: 1px solid $wcm-border-gray;
  }

  &:hover {
    background: #f7f7f7;
    color: $wcm-bright-orange;
  }

}

.element__toggle--open,
.ctools-collapsible-container:not(.ctools-collapsed) .pane-title {
  color: $wcm-black;

  &:not(.ctools-collapsible-container):after {
    content: '–';
    color: $wcm-dark-orange;
  }

  &:hover {
    color: $wcm-dark-orange;
  }
}

.element__hide {
  display: none;
  padding: 0 50px 0 10px;
}

.ctools-collapsible-content {
  padding: 0 50px 0 10px;
}

.element__hide p,
.ctools-collapsible-content p {
  margin: 20px 0;
}

.no-js {
  .element__hide {
    display: block;
  }

  .element__toggle:after {
    display: none;
  }
}

/**
 * Styles related to wcm-protected Boxes
 *
 */

/* =wcm-protected Boxes
----------------------------------------------------------*/

.wcm-protected {
  margin: 40px 0;
  padding: 30px;
  border: 1px solid $wcm-border-gray;
}

.wcm-protected__title {
  font-family: $wcm-bold;
  font-size: 40px;
  line-height: 42px;
  text-align: center;
  margin: 20px 0;
  color: $wcm-dark-gray;
}

.wcm-protected__title a {
  &:after {
    content: '\e80d';
    color: $wcm-bright-orange;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: top;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
  }
}

.wcm-protected__description {
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: $wcm-med-gray;
  text-align: center;
  margin: 0 auto 30px;
}

.wcm-protected__action {
  text-align: center;
}

.wcm-protected__action .btn--wcm {
  font-size: 16px;
  width: auto;
  display: inline-block;
  padding: 6px 42px;
}

/* =CTools Collapsible Container
----------------------------------------------------------*/

.ctools-collapsible-container {
  .ctools-toggle {
    display: none;
  }
}


/* =Radix Full-Width Layouts
(remove side gutters so it goes "full bleed" - best way to do this?)
----------------------------------------------------------*/

.container-fluid>.row>.col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

/* =Misc Utility
----------------------------------------------------------*/

.is-hidden {
  display: none;
}

.row.is-flex {
  display: flex;
  flex-wrap: wrap;

  &:before {
    display: block;
  }
}

.row.is-flex>[class*='col-'] {
  display: flex;
  flex-direction: column;
}
