/**
 * Webfonts and SVG Icon Set
 *
 */

/* =1898 WCMC Font
----------------------------------------------------------*/

@font-face {
    font-family: '1898Sans-Bold';
    src: url('../fonts/1898/1898Sans-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/1898/1898Sans-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/1898/1898Sans-Bold.woff') format('woff'), /* Modern Browsers */
         url('../fonts/1898/1898Sans-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/1898/1898Sans-Bold.svg#1898Sans-Bold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: '1898Sans-Regular';
    src: url('../fonts/1898/1898Sans-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/1898/1898Sans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/1898/1898Sans-Regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/1898/1898Sans-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/1898/1898Sans-Regular.svg#1898Sans-Regular') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: '1898Sans-Italic';
    src: url('../fonts/1898/1898Sans-Italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/1898/1898Sans-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/1898/1898Sans-Italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/1898/1898Sans-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/1898/1898Sans-Italic.svg#1898Sans-Italic') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello/font/fontello.eot?72486420');
  src: url('../fonts/fontello/font/fontello.eot?72486420#iefix') format('embedded-opentype'),
       url('../fonts/fontello/font/fontello.woff?72486420') format('woff'),
       url('../fonts/fontello/font/fontello.ttf?72486420') format('truetype'),
       url('../fonts/fontello/font/fontello.svg?72486420#fontello') format('svg');
  font-style: normal;
  font-weight: normal;
}

/* =CTA SVG Icon Set
----------------------------------------------------------*/

/*
Example showing how to change the color/size of the icons:
.cta-icon-name {
  font-size: 32px;
  color: red; // works for single-colored icons, because "fill" is set to "currentColor"
}
*/

.cta-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
}