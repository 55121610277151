/**
 * Styles for form elements such as text inputs and buttons
 *
 */

/* =Buttons
----------------------------------------------------------*/

.btn {
  border-radius: 20px;
  padding: 6px 20px;
}

.btn {
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $wcm-white;
    background-color: $wcm-bright-orange;
    border-color: $wcm-bright-orange;
  }
}

.btn--wcm {
  position: relative;
  display: block;
  width: 220px;
  margin: 0 auto;
  border-radius: 20px;
  border-width: 4px;
  font-size: 13px;
  @include button-variant($wcm-dark-orange, transparent, $wcm-bright-orange);
  &:hover,
  &:focus,
  &.active,
  .open > &.dropdown-toggle {
    color: $wcm-white;
    background-color: $wcm-dark-orange;
    border-color: $wcm-red;
    border-width: 4px;
  }
}

.btn--wcm:before {
  position: absolute;
  height: 100%;
  font-size: 125%;
  color: #fff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  content: '\e802';
  @include fontello();
  line-height: 2.0;
}

.btn--wcm:before {
  left: 70%;
  opacity: 0;
  top: 0;
}

.btn--wcm:hover:before {
  left: 86%;
  opacity: 1;
}

.btn--wcm.external-link:before {
  content: '\e811';
}

// Overrides the global external link icon
.btn--wcm.external-link:after {
  display: none;
}

.btn--small {
  font-size: 13px;
  border-radius: 10px;
  line-height: 18px;
  padding: 5px 20px;
}

/* =Form Items
----------------------------------------------------------*/

.form-control {
  max-width: 600px;
}

.form-item {
  margin-bottom: 30px;
}

.form-item label {
  font-size: 13px;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

:focus {
  outline: $wcm-dark-orange auto 5px;
}

.form-required {
  color: $wcm-red;
}

.webform-component-select label {
  display: block;
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding: 0 50px 0 15px
}

