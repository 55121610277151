/**
 * Selected styles from Panopoly
 * Many from "Kalatheme > panopoly.css"
 */

/**
 * Title and body styling
 */
#node-edit #edit-title {
  font-size: 2em;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  *width: 98%; /* IE 7 and below, as far as I know */
}

#node-edit .form-item-title {
  margin-bottom: 0;
  padding-bottom: .25em;
}

#node-edit .form-item-title label {
  display: none;
}

#node-edit #edit-body .form-type-textarea {
  padding-top: .25em;
}

#node-edit #edit-body {
  width: 100%;
}

/**
 * Pathauto styling
 */
#node-edit .form-item-path-alias label {
  float: left;
  font-weight: 400;
  font-size: 14px;
}

#node-edit .form-item-path-alias {
  font-size: .8em;
  margin-bottom: .25em;
  margin-top: .55em;
  min-height: 1.2em;
  padding: 0;
  vertical-align: middle;
}

#node-edit .form-item-path-alias input {
  margin-left: 4px;
  padding-bottom: 0;
  padding-top: 0;
  height: 20px;
}

#node-edit .form-item-path-pathauto {
  display: none;
}

/**
 * Publishing styling
 */
#node-edit .form-item-machine-name label,#node-edit .form-item-date label,#node-edit .form-item-name label
  {
  float: left;
  font-weight: 400;
  margin-right: 5px;
  margin-top: 5px;
}

/**
 * Menu Styling
 */
#node-edit .pane-node-form-menu .form-item-menu-parent label,#node-edit .pane-node-form-menu .form-item-menu-link-title label
  {
  float: left;
  font-weight: normal;
  margin-right: 4px;
  width: 49px;
}

/**
 * Featured Category Styling
 */
#node-edit .field-name-field-featured-categories>.form-item-field-featured-categories-und>label
  {
  display: none;
}

/**
 * Featured Image Styling
 */
#node-edit .pane-node-field-featured-image h2 {
  margin-top: 5px;
}

#node-edit .form-item-field-featured-image-und-0-alt label {
  float: left;
  font-weight: normal;
  margin: 4px 10px 0 0;
}

#node-edit #edit-field-featured-image-und-0-alt {
  width: 180px;
}

#node-edit #edit-field-featured-image .form-item-field-featured-image-und-0>label,#node-edit .form-item-field-featured-image-und-0-alt .description
  {
  display: none;
}

#node-edit .field-name-field-featured-image .image-preview img {
  height: auto;
  margin-bottom: 0;
  /* Overrides Bartik's default margin, which is too much */
  max-width: 100%;
}

#node-edit .field-name-field-featured-image div.image-widget-data {
  float: none;
}

#node-edit .field-name-field-featured-image .image-widget-data img {
  margin-bottom: 0;
}

#node-edit .field-name-field-featured-image .image-widget-data span.file
  {
  float: left;
  margin-right: 5%;
  margin-top: 5px;
  width: 65%;
}

#node-edit .field-name-field-featured-image .image-widget-data img {
  margin-bottom: 0;
}

#node-edit .field-name-field-featured-image .image-widget-data span.file-size
  {
  display: none;
}

#node-edit .field-name-field-featured-image .image-widget-data input#edit-field-featured-image-und-0-remove-button
  {
  max-width: 28%;
}

#node-edit .field-name-field-featured-image .image-widget-data div.form-item
  {
  clear: both;
  margin-bottom: .25em;
  margin-top: .75em;
}

#node-edit .field-name-field-featured-image .image-preview {
  width: 100%;
}

#node-edit .form-item-field-featured-image-und-0>label {
  display: none;
}

/**
 * Date/Time Styling
 */
#node-edit .container-inline-date>.form-item {
  border: 0;
  margin: 0;
}

#node-edit .container-inline-date .date-padding {
  float: none;
  padding: 0;
}

#node-edit .container-inline-date .form-item-date-time,#node-edit .container-inline-date .form-item-date-date
  {
  padding-bottom: 1em;
}

#node-edit .container-inline-date .form-item-date-time {
  clear: left;
}

#node-edit .form-item-date-time label,#node-edit .form-item-date-date label,#node-edit .form-item-name label
  {
  width: 49px;
}

#node-edit .container-inline-date .form-item-date-date .description,#node-edit .container-inline-date .form-item-date-time .description
  {
  display: none;
}

/**
 * Media Styles
 */
#media-browser-page {
  background: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 0;
}

#media-browser-page .content {
  margin-top: 0px;
}

ul#media-browser-library-list {
  margin: 0;
}

#media-browser-tabset .views-exposed-form {
  margin-bottom: 1em;
}

#media-browser-tabset .views-exposed-form .views-exposed-widget {
  float: left;
  margin-right: 15px;
}

#media-browser-tabset .views-exposed-form div.form-item {
  margin: .25em 0;
}

#media-browser-tabset .view .item-list .pager li {
  padding: 0;
}

/**
 * Misc
 */
#active-vertical-tab {
  height: 1px;
  /* fixes a small issue with vertical tabs on the administrative pages */
  overflow: hidden;
}

#views-ajax-body form {
  background-color: #fff;
  /* makes sure that our background color is white when ajaxing in views */
}

.tabledrag-toggle-weight-wrapper {
  display: none;
  /* removes the "show row weights" option. has accessibility considerations. */
}

.form-item-uid {
  position: relative;
  /* fixes positioning for auto complete items in firefox - http://drupal.org/node/1218684 */
}

/**
 * Modal
 */

body.ctools-modal-open {
  overflow: hidden;
}

#modalContent .modal-content {
  width: 100%;
  overflow: hidden;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#modalContent #modal-content {
  overflow: auto;
  width: 100% !important;
  background: white;
}

#modalContent .panels-add-content-modal {
  background: none;
  padding: 0;
  height: 100%;
  margin: -20px -20px 0;
  width: 100%;
  position: absolute;
}

#modalContent .panels-section-columns,
#modalContent .panels-categories-description {
  border: none;
}

#modalContent .panels-section-column-categories {
  margin-left: 0;
  border-right: 1px solid #ccc;
  height: 100%;
  background: white;
  overflow: auto;
  padding-bottom: 20px;
}

#modalContent .panels-section-column-categories .btn {
  border: 1px solid #999999 !important;
  line-height: inherit;
  margin: 0;
  text-align: left;
}

#modalContent .panels-categories-box {
  border: 0;
}

#modalContent .panels-categories-box a.btn {
  padding: 5px 10px;
  background-color: #fff;
  color: #5b5b5b;
}

#modalContent .panels-categories-box a.btn:hover {
  background-color: #eee;
}

#modalContent .panels-categories-box a.active {
  background: #eeeeee;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#modalContent .panels-root-content-box a.btn {
  padding: 5px 10px;
  background-color: #fff;
  color: #5b5b5b;
}

#modalContent .panels-root-content-box a.btn:hover {
  background-color: #eee;
}

#modalContent .panels-categories-description {
  padding: 20px;
}

#modalContent .panels-section-columns {
  padding-bottom: 20px;
}

#modal-content .fieldset-wrapper {
  padding: 10px;
}

#modal-content fieldset.widget-preview {
  //margin-top: 18px;
  //margin-bottom: 0;
  position: static;
  border: 1px solid #ddd;
}

#modal-content fieldset.widget-preview .panel-heading {
  position: relative;
}

#modal-content fieldset.widget-preview .panel-heading .btn {
  position: absolute;
  right: 10px;
  top: 5px;
}

#modal-content fieldset.widget-preview .panel-title {
  font-size: 14px;
}

#modal-content .ctools-auto-submit-full-form fieldset {
  width: 50%;
  display: block !important;
}

#modal-content .ctools-auto-submit-full-form fieldset fieldset {
  width: 100%;
}

#modal-content .ctools-auto-submit-full-form fieldset.widget-preview-single {
  float: right;
  margin-left: 1em;
  margin-top: 0;
  width: 45%;
  max-width: 45% !important;
}

#modal-content .ctools-auto-submit-full-form .form-actions {
  float: right;
  clear: right;
  width: 45%;
  max-width: 45%;
  text-align: right;
}

#modal-content .form-item-view-settings {
  display: block;
}

#modal-content .form-item-exposed-use-pager {
  display: block;
}

#modal-content .panopoly-magic-preview .fieldset-wrapper {
  clear: both;
  max-width: 100%;
  max-height: 200px;
  min-height: 200px;
  padding: 10px;
  overflow: hidden;
}

#modal-content .panopoly-magic-preview-single .fieldset-wrapper {
  clear: both;
  font-size: 100%;
  max-width: 100%;
  min-height: 75px;
  max-height: 375px;
  padding: 10px;
  overflow: hidden;
}

#modal-content .panopoly-magic-preview legend {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

#modal-content .panopoly-magic-preview-single {
  float: right;
  margin-left: 1em;
  margin-top: 0;
  width: 45%;
}

#modal-content .form-actions {
  float: right;
  clear: right;
  width: 45%;
  margin-left: 1em;
  text-align: right;
}

#modal-content .form-type-managed-file .form-submit.btn {
  margin: 10px 0 0;
}

#modal-content .help-block {
  font-size: 12px;
}

/**
 * Misc Modal Stylings
 */
#modal-content .form-type-textarea .form-type-select,
#modal-content .field-type-text-long .form-type-select {
  float: right;
  margin-top: 5px;
  margin-bottom: 5px;
}

#modal-content .form-type-textarea .form-type-select label,
#modal-content .field-type-text-long .form-type-select label {
  float: left;
  margin-right: .5em;
  margin-top: .5em;
  font-weight: normal;
  display: block;
  width: auto;
}

#modal-content .view-settings-wrapper .form-radios {
  display: inline;
  padding-right: 5px;
}

#modal-content .view-settings-wrapper .form-radio {
  margin-left: -20px;
}

.panels-section-column .content-type-button {
  font-size: 1em;
}

#modal-content .panopoly-magic-preview-title .content-type-button img,
#modal-content .panels-add-content-modal > .panels-modal-add-category {
  display: none;
}

#modal-content #edit-widget-preview {
  margin-top: 0;
}

#modalContent .panels-section-column-categories .content-type-button img:hover {
  border: none;
}

#modal-content .panopoly-magic-preview .fieldset-wrapper table img,
#modal-content .panopoly-magic-preview .panopoly-image-featured {
  width: 100%;
}

.pane-content .create-links,
.pane-categories .categories-links,
.pane-menus .menu-links,
.pane-users .user-links {
  float: right;
}

ul.nav > li.dropdown:after {
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  content: '';
}

.panel-display > .row {
  margin-bottom: 20px;
}

.panel-flexible .panel-separator {
  display: none;
}

div#panels-ipe-control-container {
  width: auto;
  left: 0;
  right: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

div#panels-ipe-control-container .panels-ipe-button-container {
  text-align: center;
}

div#panels-ipe-control-container .panels-ipe-button-container .panels-ipe-pseudobutton-container {
  margin: 0 5px;
}

div#panels-ipe-control-container .panels-ipe-button-container .panels-ipe-pseudobutton-container a {
  padding: 0px 10px 0px 34px;
}

div#panels-ipe-control-container .panels-ipe-button-container .panels-ipe-pseudobutton-container a:hover {
  text-decoration: none;
}

div#panels-ipe-control-container .panels-ipe-form-container form > div {
  text-align: center;
}

div#panels-ipe-control-container .panels-ipe-form-container form .form-submit {
  margin: 0 5px;
  padding: 0px 10px 0px 34px;
}

div#panels-ipe-control-container .panels-ipe-form-container form .form-submit:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

ul.panels-ipe-linkbar {
  list-style: none outside none;
}

ul.panels-ipe-linkbar > li {
  display: inline-block;
  margin: 0 0 0 5px;
}

ul.panels-ipe-linkbar > li a {
  margin: 0 !important;
}

.form-item {
  margin-top: 0;
}

/**
 * Themer toggle
 */

.panels-ipe #themer-toggle{
  bottom: 55px;
}

.navbar-tray-open.navbar-vertical #themer-toggle{
  right: 0;
  left: auto;
}

/**
 * Navbar Styling
 */
#navbar-administration .edit-shortcuts {
  display: none;
}
