/**
 * Styles related to the WCMC Search Widgets
 *
 */

/* =Search Toggle
----------------------------------------------------------*/

.wcm-search {
  display: none;
}

.search-toggle .search-toggle-button {
  display: block;
  width: 40px;
  height: 40px;
  border: 2px solid $wcm-dark-orange;
  border-radius: 50%;
  cursor: pointer;
  margin: 6px 0 6px 8px;
}

.search-toggle .cta-icon-search {
  width: 24px;
  height: 24px;
  fill: $wcm-white;
  color: $wcm-white;
  position: relative;
  top: 5px;
  left: 5px;
}

.search-toggle .search-toggle-button--close {
  display: none;
}

/*   H O V E R   */

.search-toggle .search-toggle-button:hover {
  border: 2px solid $wcm-white;
  background-color: $wcm-white;
}

.search-toggle .search-toggle-button:hover .cta-icon-search {
  color: $wcm-dark-orange;
  fill: $wcm-bright-orange;
}

/*   O P E N   */

.search-toggle.open {
  padding: 0;
}

.search-toggle.open .search-toggle-button--find {
  display: none;
}

.search-toggle.open .search-toggle-button--close {
  display: block;
}

.search-toggle.open .search-toggle-button {
  height: 50px;
  margin: 0 0 0 8px;
  background: $wcm-red;
  border: 2px solid $wcm-red;
  border-radius: 0;
}

.search-toggle.open .cta-icon-close {
  position: relative;
  width: 30px;
  height: 30px;
  top: 6px;
  left: 3px;
  fill: $wcm-bright-orange;
}

.search-toggle.open .search-toggle-button:hover .cta-icon-close {
  fill: $wcm-white;
}

/* =Full Search Form
----------------------------------------------------------*/

.global-search {
  margin-top: 4px;
}

.global-search span {
  display: block;
  overflow: hidden;
  height: 40px;
  @include breakpoint($sm) {
    height: 50px;
  }
}

.global-search span > div {
  display: inline;
}

.global-search .search-button-span {
  position: relative;
  float: right;
  margin-left: 4px;
  @include breakpoint($sm) {
    margin-top: 4px;
    width: 40px;
    height: 40px;
    background: $wcm-dark-orange;
    border: 2px solid $wcm-dark-orange;
    border-radius: 50%;
  }
}

.global-search .search-button-span.in-focus {
  background: $wcm-bright-orange;
  outline: #cf4520 auto 5px;
}

.search-button-span .btn-primary {
  color: transparent;
  position: relative;
  z-index: 1;
  background-color: transparent;
  border-color: transparent;
  width: 40px;
  &:active {
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }
}

.search-button-span .cta-icon-search {
  width: 24px;
  height: 24px;
  fill: $wcm-white;
  color: $wcm-white;
  position: relative;
  top: 5px;
  left: 5px;
  cursor: pointer;
  position: absolute;
}

.global-search .global-search-input {
  max-width: none;
  width: 100%;
  height: 100%;
  display: inline-block;
  border: none;
  border-radius: 0;
  padding: 0 4px 4px;
  text-indent: 4px;
  font-size: 30px;
  line-height: 40px;
  color: $wcm-dark-orange;
  font-style: italic;
  font-weight: 300;
  box-shadow: none;
}

.global-search .form-control::-webkit-input-placeholder { /* WebKit, Edge */
  color: $wcm-dark-orange;
}

.global-search .form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: $wcm-dark-orange;
  opacity:  1;
}

.global-search .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: $wcm-dark-orange;
}

.global-search-input:focus::-webkit-input-placeholder {
  color: transparent;
}

.global-search .form-radios {
  padding: 10px 0;
  color: $wcm-med-gray;
  @include clearfix();
  @include breakpoint($sm) {
    border-top: 1px solid $wcm-bright-orange;
    margin-top: 3px;
  }
}

.global-search .radio {
  position: relative;
  float: left;
  margin: 0 15px 0 0;
}

// Hide the browser radios and use pseudo-elements on the label to match design
.global-search input[type="radio"] {
  position: absolute;
  margin-left: -25px;
  margin-top: 5px;
  border-radius: 50%;
}

.global-search .radio + .radio {
  margin-top: 0;
}

.global-search .form-radios label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  font-size: 12px;
  margin: 0 0 5px;
}

.global-search .form-type-radio label:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  border: 1px solid $wcm-border-gray;
  border-radius: 50%;
  z-index: 10;
  background-color: #fff;
}

.global-search .form-type-radio label.checked:before {
  content: "";
  background: $wcm-bright-orange;
  border: 1px solid $wcm-border-gray;
  box-shadow: 0 0 0 5px #fff inset;
  text-align: center;
  line-height: 20px;
}

.global-search .form-type-radio label.in-focus:before {
  border: 2px solid $wcm-bright-orange;
  outline: #cf4520 auto 5px;
}

/* =Mobile Search Widget
----------------------------------------------------------*/

#top-nav .global-search {
  margin: 15px auto;
  @include breakpoint($sm) {
    display: none;
  }
}

#top-nav .global-search-input {
  min-width: 50%;
  height: 40px;
  padding: 4px 12px;
  font-size: 15px;
  border-radius: 10px;
  border: 1px solid $wcm-border-gray;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#top-nav .search-button-span {
  float: right;
  border-radius: 10px;
  border: 1px solid $wcm-border-gray;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 2px 5px;
}

#top-nav .cta-icon-search {
  left: 15px;
  fill: $wcm-bright-orange;
  color: $wcm-dark-orange;
}

#top-nav .global-search .radio {
  margin: 0 10px 0 0;
}

#top-nav .form-radios label {
  padding-left: 25px;
  line-height: 20px;
}
/* =Search Results
----------------------------------------------------------*/

.search-results {
  padding-left: 0;
}
