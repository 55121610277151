/**
 * Styles targeting the Carousel Feature
 *
 */

.slide {
  $this: &;
  &__overlay-text {
    font-size: 55px;
    line-height: 1;
    font-family: $wcm-bold;
    color: $wcm-dark-orange;
    @include breakpoint($sm) {
      display: block;
    }
    @include breakpoint($md) {
      @include center();
    }
  }
  &__text {
    background: $wcm-dark-gray;
    padding: 20px;
    @include breakpoint($md) {
      background: rgba($wcm-dark-gray, .8);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: 24px 32px;
      column-gap: 32px;
    }
  }
  &__title {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
    &, a {
      color: #fff;
    }
    @include breakpoint($md) {
      width: 30%;
      text-align: right;
      position: relative;
      top: 2px;
    }
  }
  &__caption {
    font-size: 13px;
    line-height: 18px;
    color: $wcm-white;
    @include breakpoint($sm) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__title + #{$this}__caption {
    @include breakpoint($md) {
      width: 70%;
    }
  }
}

.view-carousel {

  .thumbnail-controller{
    padding: 6px 0 0;
    background: transparent;

    @include breakpoint($sm) {
      @include clearfix();
    }

    .pager {
      margin-top: 0;
      font-weight: 600;
      color: $wcm-dark-orange;
    }

    #thumbnail-pager{
      a {
        border: none;

        > img {
          border: 3px solid $wcm-border-gray;
          opacity: .5;
        }
      }

      a > img:hover,
      .cycle-pager-active > img {
        border: 3px solid $wcm-bright-orange;
        opacity: 1;
      }

    }//#thumbnail-pager

  }//.thumbnail-controller

  .mobile-pager{
    @include clearfix();
    margin-bottom: 20px;

    p#slide-counter {
      height: auto;
      padding: 10px 0;
      background: $wcm-dark-gray;
    }

    a {
      float: left;
      width: 35%;
      font-family: $font-family-sans-serif;
      margin: 10px 0 0;
      @include text-hide();
      &:before {
        font-size: 30px;
        color: $wcm-dark-orange;
        @include fontello();
        text-decoration: none;
      }
    }

    .previous:before {
      content: '\e810';
    }

    .next:before {
      content: '\e802';
    }

  }//.mobile-pager

}//.view-carousel
