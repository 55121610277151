/**
 * Styles targeting the Emergency Banner
 * (replaces the css file in the wcmc_banner_receiver module)
 *
 * This work can be retro-fitted back to wcmc_banner_receiver after all
 * sites are on wcm_brand_base theme.
 */

// Hide now, and JS will show if it has not been dismissed
#block-wcmc-banner-receiver-wcmc-banner-display-global,
#block-wcmc-banner-receiver-wcmc-banner-display-local {
  display: none;
}

.region-header {
  @include breakpoint($sm) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1093;
  }
}

.region-header {
  position: static;
}

.logged-in .region-header {
  position: static;
}

.wcmc-banner {
  border-bottom: none;
  @include breakpoint($sm) {
    min-height: 85px;
  }
}

.wcmc-banner--global {
  background: #ed1c24;
  color: #fff;
  animation: alert 5s infinite;
}

@keyframes alert {
  0% {
    background-color: #ed1c24;
  }
  50% {
    background-color: $wcm-bright-orange;
  }
  100% {
    background-color: #ed1c24;
  }
}

.wcmc-banner--local {
  background: #000000;
  color: #fff;
}

.wcmc-banner__wrap {
  position: relative;
  width: 90%;
  margin: 0 auto;
  padding: 1em 0;
  @include clearfix();
  @include breakpoint($sm) {
    width: 720px;
  }
  @include breakpoint($md) {
    width: 940px;
  }
  @include breakpoint($lg) {
    width: 1140px;
  }
}

.wcmc-banner__indicator {
  float: left;
  padding-right: 1em;
  .ss-alert {
    display: none;
    @include breakpoint($sm) {
      display: inline;
      font-size: 50px;
      line-height: 50px;
      &:before {
        content: '\e812';
        @include fontello();
      }
    }
  }
  .ss-info {
    display: none;
    @include breakpoint($sm) {
      display: inline;
      font-size: 50px;
      line-height: 50px;
      &:before {
        content: '\e813';
        @include fontello();
      }
    }
  }
}

.wcmc-banner__message {
  width: 80%;
  float: left;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  @include breakpoint($sm) {
    font-size: 24px;
  }
  @include breakpoint($md) {
    font-size: 28px;
  }
  @include breakpoint($lg) {
    font-size: 30px;
  }
  .ss-directright {
    font-size: 10px;
  }

  a {
    border-bottom: 1px dotted;
  }
}

.wcmc-banner__more-info {
  color: #fff;
  text-decoration: underline;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
  &:visited {
    color: #fff;
  }
}

.wcmc-banner__close {
    display: block;
    float: right;
    width: 30px;
    height: 36px;
    color: $wcm-white;
    cursor: pointer;
    text-align: right;
    @include breakpoint($sm) {
      margin-right: 10px;
      padding: .25em 0 0;
    }
    &:hover {
      color: $wcm-med-gray;
      text-decoration: none;
    }
    .ss-delete {
      font-size: 36px;
      line-height: 36px;
      &:before {
        content: '\e809';
        @include fontello();
      }
    }
  }
